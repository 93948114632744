// src/components/ImageList.js
import useAuthCheck from './useAuthCheck';

const AuthCheck = () => {
  const isAuth = useAuthCheck();
  if(!isAuth) return <div>リダイレクトする</div>;
  if(isAuth) return <div>認証成功</div>;
};

export default AuthCheck;
