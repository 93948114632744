import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
  const [password, setPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://akolibrary.org:4000/api/login', 
        {password: password},
        {withCredentials: true}
    );
      setResponseMessage(response.data.message); // 成功メッセージを設定
    } catch (err) {
      setError('ログインに失敗しました。'); // エラーメッセージを設定
    } finally {
      setLoading(false); // ローディング終了
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)} // 入力値を更新
        placeholder="Enter your password"
      />
      <button onClick={handleLogin} disabled={loading}>
        {loading ? 'Logging in...' : 'Login'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* エラーを表示 */}
      <p>{responseMessage}</p> {/* サーバーからの応答を表示 */}
    </div>
  );
};

export default Login;
