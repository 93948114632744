import React, { useEffect, useState } from 'react';
import axios from 'axios';

const useAuthCheck = (skipAuthCheck = false) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    if (skipAuthCheck) {
      // 認証チェックをスキップ
      setLoading(false);
      setIsAuthenticated(false); // ログインしていないと仮定
      return;
    }

    // APIエンドポイントのURL
    (async () => {
      try {
        const response = await axios.get('https://akolibrary.org:4000/api/auth-status', {
          withCredentials: true,
        });
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (err) {
        setError('Error fetching authentication status');
      } finally {
        setLoading(false); // ローディング終了
      }
    })();
  }, [skipAuthCheck]);

  // loading、error、isAuthenticatedを返す
  return { loading, error, isAuthenticated };
};

export default useAuthCheck;
