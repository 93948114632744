import React, { useEffect, useState, useRef  } from 'react';
import axios from 'axios';
import Gallery from 'react-photo-gallery';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS をインポート
import { Button } from 'react-bootstrap';

const ListImage = () => {
    const location = useLocation();
    const [photos, setPhotos] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // クエリパラメータを取得
    const queryParams = new URLSearchParams(location.search);
    const prefix = queryParams.get('prefix');
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!prefix) {
            setError('Prefix is not defined');
            setLoading(false);
            return;
        }

        (async () => {
            try {
                const response = await axios.get(`https://akolibrary.org:4000/list?prefix=${encodeURIComponent(prefix)}`, {
                    withCredentials: true,
                });

                const imageUrls = response.data.images.map((img) => img.url);

                // 画像のサイズを取得するヘルパー関数
                const getImageSize = (url) =>
                    new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = () => resolve({ src: url, width: img.width, height: img.height });
                        img.onerror = reject;
                        img.src = url;
                    });

                // 全画像のサイズを取得
                const formattedPhotos = await Promise.all(imageUrls.map(getImageSize));
                setPhotos(formattedPhotos);
            } catch (err) {
                setError('Error fetching image list');
            } finally {
                setLoading(false);
            }
        })();
    }, [prefix]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const slideClick = async () => {
        const formattedPrefix = prefix.endsWith('/') ? prefix.slice(0, -1) : prefix;
        try {
            const response = await axios.put('https://akolibrary.org:4000/slideChange/', 
                {
                    content: formattedPrefix // ボタンのクリック時に送信するデータ
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json' // リクエストヘッダーの設定
                    },
                    withCredentials: true
                }
            );
            console.log('Response:', response.data);
            alert('Slide show updated successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update the slide show.');
        }
    };

    const handleClick = () => {
        // ファイル選択ダイアログを開く
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            alert('No files selected');
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        try {
            const response = await axios.post(`https://akolibrary.org:4000/upload?prefix=${encodeURIComponent(prefix)}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            console.log('Response:', response.data);
            alert('Files uploaded successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to upload files.');
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <Gallery photos={photos} />
            <div>
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                />
                <Button
                    style={{
                        position: 'fixed',
                        bottom: '70px',
                        right: '70px',
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        backgroundColor: '#36dc35', // ボタンの色
                        color: 'white',
                        fontSize: '84px',
                        lineHeight: '1',
                        verticalAlign: 'middle', // テキストの縦位置を中央揃え
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        border: 'none',
                        fontWeight: 'bold'
                    }}
                    onClick={handleClick}
                >
                    ＋
                </Button>
            </div>
            <Button
                value={prefix}
                style={{
                    position: 'fixed',
                    bottom: '70px',
                    left: '70px',
                    width: '400px',
                    height: '100px',
                    borderRadius: '10%',
                    backgroundColor: '#36dc35', // ボタンの色
                    color: 'white',
                    fontSize: '44px',
                    lineHeight: '1',
                    verticalAlign: 'middle', // テキストの縦位置を中央揃え
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    border: 'none',
                    fontWeight: 'bold'
                }}
                onClick={slideClick}
            >
                スライドショー
            </Button>
        </div>
    );
};

export default ListImage;