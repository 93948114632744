import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button, Row, Col, Container, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Prefix = () => {
  const [folderList, setFolderList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [albumName, setAlbumName] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`https://akolibrary.org:4000/prefix`, {
          withCredentials: true,
        });
        setFolderList(response.data.folders);
      } catch (err) {
        setError('Error fetching folder list');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const prefixToRemove = "あこちゃん/";

  const handleFileUpload = async () => {
    const files = fileInputRef.current.files;
    if (!files || files.length === 0) {
      alert('写真を1枚以上選択してください');
      return;
    }

    // Append a trailing slash to the album name if it exists
    const prefix = `${prefixToRemove}${albumName.trim()}/`;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    try {
      const response = await axios.post(`https://akolibrary.org:4000/upload?prefix=${encodeURIComponent(prefix)}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      console.log('Response:', response.data);
      alert('写真をアップロードしました。');
      setShowModal(false);
      setAlbumName('');
      window.location.href = `https://akolibrary.org:3000/`;
    } catch (error) {
      console.error('Error:', error);
      alert('アップロードに失敗しました。');
    }
  };

  return (
    <Container className="mt-4">
      {/* Display list of folders */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {folderList.map((folder, index) => {
          const displayName = folder
            .replace(prefixToRemove, "")
            .replace(/\/$/, "");

          return (
            <Button
              key={index}
              value={folder}
              variant="primary"
              style={{
                width: '100%',
                padding: '40px',
                fontSize: '1.5rem',
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                backgroundColor: 'white',
                color: 'black',
                fontWeight: 'bold',
                borderColor: 'black',
                borderWidth: '3px'
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
              onClick={(e) => {
                const prefix = e.target.value;
                window.location.href = `https://akolibrary.org:3000/list?prefix=${encodeURIComponent(prefix)}`;
              }}
            >
              {displayName}
            </Button>
          );
        })}
      </div>

      {/* Hidden input for file selection */}
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        multiple
        onChange={handleFileUpload}
      />

      {/* Upload button to show modal */}
      <Button
        style={{
          position: 'fixed',
          bottom: '70px',
          right: '70px',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          backgroundColor: '#36dc35',
          color: 'white',
          fontSize: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          border: 'none',
          fontWeight: 'bold',
        }}
        onClick={() => setShowModal(true)}
      >
        ＋
      </Button>

      {/* Modal for album name input */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>アルバム名を入力</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAlbumName">
              <Form.Label>アルバム名</Form.Label>
              <Form.Control
                type="text"
                placeholder="アルバム名を入力"
                value={albumName}
                onChange={(e) => setAlbumName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>画像を選択</Form.Label>
              <Form.Control
                ref={fileInputRef}
                type="file"
                multiple
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={handleFileUpload}>
            アップロード
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Prefix;
