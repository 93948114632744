import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import useAuthCheck from './components/useAuthCheck';
import Login from './components/login';
import AuthCheck from './components/authCheck';
import ListImage from './components/list';
import Prefix from './components/prefix';

function App() {
  const isLoginRoute = window.location.pathname === '/login';
  const { loading, error, isAuthenticated } = useAuthCheck(isLoginRoute);

  if (loading) {
    return <div>Loading...</div>; // 認証処理中の表示
  }

  if (error && !isLoginRoute) {
    return <div>Error: {error}</div>; // エラー表示
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/check"
            element={
              isAuthenticated ? <AuthCheck /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/list"
            element={
              isAuthenticated ? <ListImage /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated ? <Prefix /> : <Navigate replace to="/login" />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
